export const FrappeBlendedColdCoffeeItems = [
  {
    name: "Lighting Frappucino",
    slug: "lighting-frappucino",
    price: 750,
    category: "bar-frappe-blended-cold-coffee",
    description: "Lighting Frappucino",
    image: require("../../../Images/lighting frappucino.jpeg"),
    types: [],
  },
  {
    name: "Spanish Biscoff (Signature)",
    slug: "spanish-biscoff",
    price: 799,
    category: "bar-frappe-blended-cold-coffee",
    description: "Spanish Biscoff (Signature)",
    image: require("../../../Images/spanish-biscoff.jpg"),
    types: [],
  },
  {
    name: "Caramel Ribbon Crunch",
    slug: "caramel-ribbon-crunch",
    price: 750,
    category: "bar-frappe-blended-cold-coffee",
    description: "Caramel Ribbon Crunch",
    image: require("../../../Images/caramel ribbon  crunch.webp"),
    types: [],
  },
  {
    name: "Ice Caramel Macchito",
    slug: "ice-caramel-macchito",
    price: 750,
    category: "bar-frappe-blended-cold-coffee",
    description: "Ice Caramel Macchito",
    image: require("../../../Images/Iced-Caramel-Macchiato.jpg"),
    types: [],
  },
  {
    name: "Mochachino",
    slug: "mochachino",
    price: 750,
    category: "bar-frappe-blended-cold-coffee",
    description: "Mochachino",
    image: require("../../../Images/mochachino.jpg"),
    types: [],
  },
];