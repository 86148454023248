export const TeaItems = [
  {
    name: "English Break Fast",
    slug: "english-break-fast",
    price: 350,
    category: "bar-tea",
    description: "English Break Fast",
    image: require("../../../Images/English break fast tea.jpeg"),
    types: [],
  },
  {
    name: "Cardanom",
    slug: "cardanom",
    price: 399,
    category: "bar-tea",
    description: "Cardanom",
    image: require("../../../Images/CardamomTea.jpg"),
    types: [],
  },
  {
    name: "Green Tea",
    slug: "green-tea",
    price: 339,
    category: "bar-tea",
    description: "Jasmine, Lemon Grass, Peach, Mint",
    image: require("../../../Images/Green-Tea.jpg"),
    types: [],
  },
  {
    name: "Mix Tea",
    slug: "mix-tea",
    price: 399,
    category: "bar-tea",
    description: "Mix Tea",
    image: require("../../../Images/Mix tea.jpg"),
    types: [],
  },
];
