export const BlendedMocktailItems = [
  {
    name: "Mint Margrita",
    slug: "mint-margrita",
    price: 599,
    category: "bar-blended-mocktail",
    description: "Mint Margrita",
    image: require("../../../Images/mint-margrita.jpg"),
    types: [],
  },
  {
    name: "Pina Colada",
    slug: "pina-colada",
    price: 699,
    category: "bar-blended-mocktail",
    description: "Pina Colada",
    image: require("../../../Images/pina-colada.jpg"),
    types: [],
  },
  {
    name: "Lemonade",
    slug: "lemonade",
    price: 399,
    category: "bar-blended-mocktail",
    description: "Lemonade",
    image: require("../../../Images/lemonade.jpg"),
    types: [],
  },
  {
    name: "Pearson Margrita (Signature)",
    slug: "pearson-margrita",
    price: 990,
    category: "bar-blended-mocktail",
    description: "Pearson Margrita",
    image: require("../../../Images/psl-margrita.jpg"),
    types: [],
  },
  {
    name: "Fresh Lime",
    slug: "fresh-lime",
    price: 399,
    category: "bar-blended-mocktail",
    description: "Fresh Lime",
    image: require("../../../Images/fresh-lime.webp"),
    types: [],
  },
];