export const ColdCoffeeItems = [
  {
    name: "Ice Spanish (Signature)",
    slug: "ice-spanish",
    price: 750,
    category: "bar-cold-coffee",
    description: "Ice Spanish (Signature)",
    image: require("../../../Images/ice spanish coffee.jpg"),
    types: [],
  },
  {
    name: "Ice Mocha",
    slug: "ice-mocha",
    price: 699,
    category: "bar-cold-coffee",
    description: "Ice Mocha",
    image: require("../../../Images/Iced_Cafe_Mocha.jpg"),
    types: [],
  },
  {
    name: "Ice Latte",
    slug: "ice-latte",
    price: 599,
    category: "bar-cold-coffee",
    description: "Ice Latte",
    image: require("../../../Images/ice latte.jpg"),
    types: [],
  },
  {
    name: "Ice Flavor Latte",
    slug: "ice-flavor-latte",
    price: 750,
    category: "bar-cold-coffee",
    description: "Ice Flavor Latte - Hazelnut, Caramel, Vanila",
    image: require("../../../Images/ice flavour latte.jpg"),
    types: [],
  },
  {
    name: "Ice Americano",
    slug: "ice-americano",
    price: 499,
    category: "bar-cold-coffee",
    description: "Ice Americano",
    image: require("../../../Images/Ice-Americano.jpg"),
    types: [],
  },
  {
    name: "Ice Chocolate",
    slug: "ice-chocolate",
    price: 599,
    category: "bar-cold-coffee",
    description: "Ice Chocolate",
    image: require("../../../Images/ice chocolate.jpg"),
    types: [],
  },
];
