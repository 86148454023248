export const WildShakeItems = [
  {
    name: "Lotus Biscoff (Signature)",
    slug: "lotus-biscoff",
    price: 799,
    category: "bar-wild-shake",
    description: "Lotus Biscoff (Signature)",
    image: require("../../../Images/lotus biscoff shake.jpg"),
    types: [],
  },
  {
    name: "Cookies n Cream",
    slug: "cookies-n-cream",
    price: 750,
    category: "bar-wild-shake",
    description: "Cookies n Cream",
    image: require("../../../Images/cookies-and-cream.jpg"),
    types: [],
  },
  {
    name: "Nutella",
    slug: "nutella",
    price: 799,
    category: "bar-wild-shake",
    description: "Nutella",
    image: require("../../../Images/Nutella-milkshake.jpg"),
    types: [],
  },
  {
    name: "Over Dose",
    slug: "over-dose",
    price: 750,
    category: "bar-wild-shake",
    description: "Over Dose",
    image: require("../../../Images/Over Dose shake.jpg"),
    types: [],
  },
  {
    name: "Specter Vanilla",
    slug: "specter-vanilla",
    price: 750,
    category: "bar-wild-shake",
    description: "Specter Vanilla",
    image: require("../../../Images/spector vianlla.jpg"),
    types: [],
  },
];
