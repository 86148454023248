export const HotCoffeeItems = [
  {
    name: "Espresso",
    slug: "espresso",
    price: 350,
    category: "bar-hot-coffee",
    description: "Espresso",
    image: require("../../../Images/Hot_Espresso.jpg"),
    types: [],
  },
  {
    name: "Doppio",
    slug: "doppio",
    price: 449,
    category: "bar-hot-coffee",
    description: "Doppio",
    image: require("../../../Images/Doppio.jpg"),
    types: [],
  },
  {
    name: "Americano",
    slug: "americano",
    price: 499,
    category: "bar-hot-coffee",
    description: "Americano",
    image: require("../../../Images/americano.webp"),
    types: [],
  },
  {
    name: "Latte",
    slug: "latte",
    price: 599,
    category: "bar-hot-coffee",
    description: "Latte",
    image: require("../../../Images/latte.webp"),
    types: [],
  },
  {
    name: "Spanish Latte",
    slug: "spanish-latte",
    price: 750,
    category: "bar-hot-coffee",
    description: "Spanish Latte",
    image: require("../../../Images/Spanish-Latte.webp"),
    types: [],
  },
  {
    name: "Flavor Latte",
    slug: "flavor-latte",
    price: 750,
    category: "bar-hot-coffee",
    description: "Flavor Latte - Hezelnut, Caramel, Vanila",
    image: require("../../../Images/flavour latte.jpeg"),
    types: [],
  },
  {
    name: "Nutty Cinnamon",
    slug: "nutty-cinnamon",
    price: 750,
    category: "bar-hot-coffee",
    description: "Nutty Cinnamon",
    image: require("../../../Images/Nutty Cinnamom.webp"),
    types: [],
  },
  {
    name: "Mocha",
    slug: "mocha",
    price: 649,
    category: "bar-hot-coffee",
    description: "Mocha",
    image: require("../../../Images/Mocha.jpg"),
    types: [],
  },
  {
    name: "Cappuccino",
    slug: "cappuccino",
    price: 649,
    category: "bar-hot-coffee",
    description: "Cappuccino",
    image: require("../../../Images/cappuccino.webp"),
    types: [],
  },
  {
    name: "Cartado",
    slug: "cartado",
    price: 550,
    category: "bar-hot-coffee",
    description: "Cartado",
    image: require("../../../Images/cortado-.webp"),
    types: [],
  },
  {
    name: "Hot Chocolate",
    slug: "hot-chocolate",
    price: 699,
    category: "bar-hot-coffee",
    description: "Hot Chocolate",
    image: require("../../../Images/Hot-Chocolate.jpg"),
    types: [],
  },
];
