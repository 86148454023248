export const SmoothiesItems = [
  {
    name: "Strawberry",
    slug: "strawberry",
    price: 699,
    category: "bar-smoothies",
    description: "Strawberry Smoothie",
    image: require("../../../Images/strawberry-smoothie.jpg"),
    types: [],
  },
  {
    name: "Peach",
    slug: "peach",
    price: 699,
    category: "bar-smoothies",
    description: "Peach Smoothie",
    image: require("../../../Images/peach-smoothie.jpg"),
    types: [],
  },
  {
    name: "Mix Berry",
    slug: "mix-berry",
    price: 699,
    category: "bar-smoothies",
    description: "Mix Berry",
    image: require("../../../Images/mix-berry.jpg"),
    types: [],
  },
  {
    name: "Tropical",
    slug: "tropical",
    price: 699,
    category: "bar-smoothies",
    description: "Tropical",
    image: require("../../../Images/tropical.jpg"),
    types: [],
  },
];
