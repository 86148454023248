import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../../redux/slices/cartSlice";
import "./Header.css";

export const Header = () => {
  const [showCartModal, setShowCartModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < cartItems?.length; i++) {
      total += (cartItems[i]?.price * cartItems[i]?.quantity);
    }
    setTotalPrice(total)
  }, [cartItems])

  return (
    <>
      <header className="sticky">
        <Container>
          {["lg"].map((expand) => (
            <Navbar
              key={expand}
              expand={expand}
              variant="dark"
              className="Navbar-Custom"
            >
              <Container>
                <div className="nav-parent">
                  <Link to="/">
                    <img
                      className="logotransition"
                      width={126}
                      src={require("../../../Images/Artboard-1-1.png")}
                      alt="Pearson Specter Lit Logo"
                    />
                  </Link>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                  />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    className='flex-grow-0'
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        <img
                          src={require("../../../Images/Artboard-1-1.png")}
                          alt="Pearson Specter Litt Logo"
                        />
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-space-between align-items-center flex-grow-1">
                        <NavLink
                          className="nav-link Nav-a text-decoration-none inline-flex bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] bg-clip-text  text-transparent"
                          to="/"
                        >
                          <span>
                            Home
                          </span>
                        </NavLink>
                        <NavLink
                          className="nav-link Nav-a inline-flex animate-background-shine bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] bg-clip-text  text-transparent"
                          to="/menu"
                        >
                          <span>
                            Menu
                          </span>
                        </NavLink>

                        <NavLink
                          className="nav-link Nav-a inline-flex animate-background-shine bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] bg-clip-text  text-transparent"
                          to="/about-us/"
                        >
                          <span>
                            About Us
                          </span>
                        </NavLink>

                        <NavLink
                          className="nav-link Nav-a inline-flex animate-background-shine bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] bg-clip-text  text-transparent"
                          to="/contact-us/"
                        >
                          <span>
                            Contact Us
                          </span>
                        </NavLink>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                  <div className="cart-img-div img-div d-inline-block">
                    <button className="bg-none border-0" onClick={() => setShowCartModal(!showCartModal)}>
                      <img className="cart-img" src={require('../../../Images/cartIcon.png')} alt="Bag" />
                    </button>
                    {cartItems?.length > 0 && (<span className="cart-items-length" onClick={() => setShowCartModal(!showCartModal)}>{cartItems?.length}</span>)}
                    {showCartModal && (
                      <div className="cart-modal-parent">
                        <div className="cart-modal position-relative">
                            {cartItems?.length < 1 ? (
                              <p className="no-items-para text-light text-center">
                                Bag is Empty
                              </p> ):(
                              <>
                                <div className="cart-items-listing-modal">
                                {cartItems?.map((item) => (
                                  <div className="cart-item-listing-modal" key={item.slug + item.type}>
                                    <div className="img-div">
                                      <img src={item?.image} alt={item?.name} />
                                    </div>
                                    <div className="item-detail">
                                      <span className="item-name">
                                        {item?.name}{item?.type ? ` - ${item?.type}` : ''}
                                      </span>
                                      <div className="d-flex justify-content-between">
                                      <span className="item-quantity">
                                        {`${item?.quantity} x Rs ${item?.price}`}
                                      </span>
                                      <button className="cross-cart-btn" onClick={() => dispatch(removeItem(item))} >x</button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                </div>
                                <p className="subtotal-cart-modal-para mb-2">Subtotal: Rs{totalPrice}.00(incl .tax)</p>
                                <div className="d-flex justify-content-end">
                                  <Link to='/checkout' onClick={() => setShowCartModal(false)} className="checkout-btn-cart-modal">Checkout</Link>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            </Navbar>
          ))}

        </Container>
      </header>
    </>
  );
};
